import styled, { css, keyframes } from 'styled-components';
import { Button, Row, Typography } from 'antd';
import { timingFunctions } from 'polished';
import { CaretDownOutlined } from '@ant-design/icons';

import theme from 'styles/theme';

const showScroll = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const hideScroll = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const scroll = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
`;

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
  $scrollHappened?: boolean;
}

export const ScrollIndicator = styled(Typography.Title)<CommonProps>`
  position: absolute;
  bottom: ${(props) => (props.$isMobile ? '80px' : '30px')};
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  color: ${theme.colors.white};
  opacity: 0;
  animation: 1s ${timingFunctions('easeOutQuad')} 2s 1 forwards ${showScroll};
  ${(props) =>
    props.$scrollHappened &&
    css`
      animation: 0.5s ${timingFunctions('easeOutQuad')} 0s 1 forwards
        ${hideScroll};
    `};

  > span {
    color: ${theme.colors.white};
  }
`;

export const ScrollIndicatorIcon = styled(CaretDownOutlined)`
  display: block;
  animation: 1.5s ${timingFunctions('easeOutQuad')} infinite ${scroll};
`;

export const AnnouncementBanner = styled(Row)`
  &&& {
    background-color: ${theme.colors.accent};
    padding: 10px 10px 10px 20px;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AnnouncementBannerDescription = styled(
  Typography.Text
)<CommonProps>`
  &&& {
    color: ${theme.colors.primaryDeep};
    font-size: ${(props) => (props.$isMobile ? '12px' : 'inherit')};
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
`;

export const Container = styled.div<CommonProps>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 25px;
  max-width: ${theme.size.maxWidth};
  margin: 0 auto;
  padding: ${(props) => (props.$isMobile ? '0 30px' : '0 60px')};
`;

export const SuperGraphic = styled.div<CommonProps>`
  background-image: ${(props) =>
    props.$isMobile
      ? "url('/images/super-graphic-mobile.png')"
      : "url('/images/super-graphic-v7.png')"};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;

  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) => (props.$isInView ? 'scale(1)' : 'scale(1.25)')};

  transition-property: opacity, transform;
  transition-duration: 2s;
  transition-timing-function: ${timingFunctions('easeOutQuart')};
  transition-delay: 0s;
`;

export const Content = styled.div<CommonProps>`
  margin-top: ${(props) => (props.$isMobile ? '-50px' : '0')};
`;

export const Logo = styled.img<CommonProps>`
  position: relative;
  width: 70%;
  min-width: 250px;
  margin-bottom: ${(props) => (props.$isMobile ? '10px' : '30px')};
  opacity: ${(props) => (props.$isInView ? 1 : 0)};

  transition-property: opacity;
  transition-duration: 3s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0s;
`;

export const Launching = styled(Typography.Text)`
  &&& {
    display: block;
    color: ${theme.colors.white};
  }
`;

export const Cta = styled(Button)<CommonProps>`
  &&& {
    display: block;
    min-width: 200px;
    margin: ${(props) => (props.$isMobile ? '20px 0 0' : '40px 0 0')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition:
      background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 1s ${timingFunctions('easeOutQuad')} 4s,
      transform 1s ${timingFunctions('easeOutQuad')} 4s;

    &:hover {
      &&& {
        border-color: #bcb4e9;
        background-color: #bcb4e9;
        color: white;
      }
    }
  }
`;

export const CtaApply = styled(Button)<CommonProps>`
  &&& {
    display: block;
    min-width: 200px;
    margin: ${(props) => (props.$isMobile ? '20px 0 0' : '40px 0 0')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};
    border-color: ${theme.colors.secondary};
    color: ${theme.colors.secondary};

    &:hover {
      &&& {
        border-color: #bcb4e9;
        background-color: #bcb4e9;
        color: white;
      }
    }

    transition:
      background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 1s ${timingFunctions('easeOutQuad')} 4s,
      transform 1s ${timingFunctions('easeOutQuad')} 4s;
  }
`;
