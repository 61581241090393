import styled from 'styled-components';
import { Button, Row, Typography } from 'antd';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
  $scrollHappened?: boolean;
}

export const Wrapper = styled.div`
  position: relative;
  background: ${theme.colors.white};
`;

export const Container = styled.div<CommonProps>`
  /* position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; */
  margin: 0 auto;
  max-width: ${theme.size.maxWidth};
  padding: ${(props) => (props.$isMobile ? '15px' : '80px 60px')};
`;

export const Content = styled.div<CommonProps>`
  position: relative;
  padding-top: 56.25%;
  border-radius: 25px;
  overflow: hidden;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
