import { Grid } from 'antd';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player'), {
  ssr: false,
});

import { Wrapper, Container, Content } from './styles';

export default function HoldingPage() {
  const screens = Grid.useBreakpoint();

  return (
    <>
      <Wrapper>
        <Container $isMobile={!screens.sm}>
          <Content $isMobile={!screens.sm}>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=6scJO7qLs2A"
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          </Content>
        </Container>
      </Wrapper>
    </>
  );
}
