import { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import { Space, Grid } from 'antd';
import { useInView } from 'framer-motion';
import Link from 'next/link';

import WaitlistModal from 'components/WaitlistModal';
import AnimatedTitle from 'components/AnimatedTitle';

import theme from 'styles/theme';
import {
  Wrapper,
  Container,
  Content,
  SuperGraphic,
  Logo,
  ScrollIndicator,
  ScrollIndicatorIcon,
  Cta,
  CtaApply,
  AnnouncementBanner,
  AnnouncementBannerDescription,
} from './styles';

export interface HomePageHeroProps {
  copy: any;
}

export default function HoldingPage({ copy }) {
  const screens = Grid.useBreakpoint();
  const holdingRef = useRef(null);
  const isInView = useInView(holdingRef, { once: true });

  const [scrollHappened, setScrollHappened] = useState(false);
  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState('Join the waitlist');

  const handleScroll = useCallback(() => {
    setScrollHappened(true);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { once: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (localStorage.getItem('PitchedIt-waitlistId')) {
      setWaitlistCtaCopy('Your waitlist status');
    }
  }, []);

  const getStyleForTitle = useMemo(() => {
    if (screens.xl)
      return {
        fontSize: '30px',
        lineHeight: '40px',
      };
    if (screens.xs)
      return {
        fontSize: '20px',
        lineHeight: '30px',
      };
    return { fontSize: '28px', lineHeight: '38px' };
  }, [screens]);

  return (
    <>
      <WaitlistModal
        isOpen={waitlistModalOpen}
        setIsOpen={setWaitlistModalOpen}
        onComplete={() => {}}
      />
      <Wrapper>
        <ScrollIndicator
          level={5}
          $scrollHappened={scrollHappened}
          $isMobile={!screens.sm}
        >
          <span>Scroll</span>
          <ScrollIndicatorIcon />
        </ScrollIndicator>
        <AnnouncementBanner align="middle" justify="space-between">
          <AnnouncementBannerDescription $isMobile={!screens.sm}>
            We&apos;re thrilled to announce that Shuttle has received
            authorisation from the Central Bank of Ireland to operate across
            Europe!
          </AnnouncementBannerDescription>
        </AnnouncementBanner>
        <SuperGraphic $isMobile={!screens.sm} $isInView={isInView} />
        <Container ref={holdingRef} $isMobile={!screens.sm}>
          <Content $isMobile={!screens.sm}>
            <Logo
              $isMobile={!screens.sm}
              $isInView={isInView}
              src="/images/shuttle-logo.svg"
              alt="Shuttle"
            />
            <AnimatedTitle
              text="Simple and unintimidating access to investment opportunities once reserved for the ultra-wealthy."
              isInView={isInView}
              delay={1}
              style={{
                ...getStyleForTitle,
                color: theme.colors.white,
                fontWeight: 300,
                maxWidth: 700,
              }}
            />
            <AnimatedTitle
              text="Launching in November."
              isInView={isInView}
              delay={3.5}
              style={{
                ...getStyleForTitle,
                color: theme.colors.white,
                fontWeight: 600,
                maxWidth: 700,
                marginTop: !screens.sm ? '0' : '30px',
              }}
            />
            <Space
              size={screens.sm ? 20 : 0}
              direction={screens.sm ? 'horizontal' : 'vertical'}
            >
              <Cta
                $isMobile={!screens.sm}
                $isInView={isInView}
                size="large"
                type="primary"
                onClick={() => setWaitlistModalOpen(true)}
              >
                {waitlistCtaCopy}
              </Cta>
              <Link href="/apply">
                <CtaApply
                  $isMobile={!screens.sm}
                  $isInView={isInView}
                  size="large"
                >
                  Apply for investment
                </CtaApply>
              </Link>
            </Space>
          </Content>
        </Container>
      </Wrapper>
    </>
  );
}
