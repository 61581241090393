import { useEffect, useRef, CSSProperties } from 'react';
import { useAnimation } from 'framer-motion';
import { Grid } from 'antd';

import { StyledTitle, StyledWord, StyledCharacter } from './styles';

export interface AnimatedTitleProps {
  text: string;
  isInView: boolean;
  level?: 1 | 2 | 3 | 4 | 5;
  style?: CSSProperties;
  isHero?: boolean;
  delay?: number;
}

export default function AnimatedTitle({
  text,
  isInView,
  level = 1,
  style = {},
  isHero = false,
  delay = 0,
}: AnimatedTitleProps) {
  const screens = Grid.useBreakpoint();
  const ctrls = useAnimation();
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (isInView) {
      setTimeout(
        () => {
          if (mountedRef.current) {
            ctrls.start('visible');
          }
        },
        10 + delay * 1000
      );
    }

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      setTimeout(() => {
        ctrls.start('visible');
      }, delay * 1000);
    }
  }, [screens, isInView]);

  let animIndex = 0;

  const characterAnimation = () => {
    animIndex += 1;
    return {
      hidden: {
        opacity: 0,
        y: '15px',
      },
      visible: {
        opacity: 1,
        y: '0px',
        transition: {
          delay: animIndex * 0.03,
          duration: 0.8,
          ease: [0.2, 0.65, 0.3, 0.9],
        },
      },
    };
  };

  return (
    <StyledTitle aria-label={text} role="heading" level={level} style={style}>
      {text.split(' ').map((word, wordIndex) => {
        if (word === '\n' && screens.md) {
          return <br key={`word_${wordIndex}`} />;
        }
        return (
          <StyledWord
            aria-hidden="true"
            key={`word_${wordIndex}`}
            $isHero={isHero}
            $isScreensLg={screens.md}
          >
            {word.split('').map((character, characterIndex) => {
              return (
                <StyledCharacter
                  aria-hidden="true"
                  initial="hidden"
                  key={`character_${characterIndex}`}
                  animate={ctrls}
                  variants={characterAnimation()}
                  $isHero={isHero}
                >
                  {character}
                </StyledCharacter>
              );
            })}
          </StyledWord>
        );
      })}
    </StyledTitle>
  );
}
